<template>
  <div>
    <div class="pa-3 ">
      <v-data-table
        fixed-header
        class="elevation-1"
        height="410px"
        dense
        single-select
        :items="client_list"
        item-key="id"
        :headers="selection"
        :loading="Loading"
        :search="search"
        loading-text="Chargement en cours ... Merci de patienter"
        @click:row="rowClick"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-menu bottom left :close-on-content-click="closeOnContentClick">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  small
                  color="indigo"
                  class="mx-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  :class="[{ 'blue lighten-5': item.selected }, 'white']"
                  v-for="(item, i) in headers"
                  :key="i"
                >
                  <v-list-item-action>
                    <v-checkbox v-model="item.selected"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-toolbar-title>Liste des Clients </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              hide-details
              single-line
              solo-inverted
              clearable
              dense
              flat
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              class="pr-6"
            >
            </v-text-field>

            <template>
              <div id="app">
                <!--downloadexcel
                  class="btn"
                  :fetch="devis"
                  name="Devis.xls"
                  worksheet="Devis"
                  :before-generate="startDownload"
                  :before-finish="finishDownload"
                >
                  <v-btn
                    fab
                    dark
                    small
                    color="indigo"
                    class="ma-2"
                    :disabled="total == 0"
                  >
                    <v-icon dark>
                      mdi-file-excel
                    </v-icon>
                  </v-btn>
                </downloadexcel-->
              </div>
            </template>
            <template>
              <v-btn
                fab
                dark
                small
                color="indigo"
                @click="clientnew"
                :disabled="!isDisable"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>

            <template>
              <confirmdialog ref="confirm" />
            </template>
          </v-toolbar>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="snackbar_timeout"
              top
              :color="snackbar_color"
            >
              {{ snackbar_text }}
            </v-snackbar>
          </template>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small class="mr-2" @click="delRecord(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </div>
    <clientform
      v-model="showScheduleForm"
      :client_id="parseInt(client_id)"
      :key="client_id"
    >
    </clientform>
  </div>
</template>

<script>
import clientform from "../components/ClientForm.vue";
import DELETE_CLIENT from "../graphql/Devis/DELETECLIENT.gql";
//import downloadexcel from "vue-json-excel";
import confirmdialog from "../components/ConfirmDialog.vue";

export default {
  components: {
    clientform,
    confirmdialog,
  },

  data: () => ({
    showScheduleForm: false,
    closeOnContentClick: false,
    search: null,
    headers: [
      {
        text: "Date creation",
        value: "created_at",
        enum: "CREATED_AT",
        selected: true,
      },

      {
        text: "Nom",
        value: "name",
        enum: "NAME",
        selected: true,
      },
      {
        text: "Notation",
        value: "notation",
        enum: "NOTATION",
        selected: true,
      },
      {
        text: "Telephone",
        value: "tel",
        enum: "TEL",
        selected: true,
      },

      { text: "E-mail", value: "email", enum: "EMAIL", selected: true },

      { text: "Actions", value: "actions", sortable: false, selected: true },
    ],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    Newdevis: "",
    field: "CREATED_AT",
    order: "DESC",
    client_id: "1",
  }),

  computed: {
    selection() {
      return this.headers.filter((item) => {
        if (item.selected === true) {
          return item;
        }
      });
    },
    isDisable() {
      return this.$store.state.newclient != "";
    },

    Loading() {
      return this.$store.state.loading;
    },
    client_list() {
      let list = this.$store.state.client_list;
      list.sort(this.sortBy("name"));
      return list;
    },
  },
  watch: {},
  created() {},

  mounted() {
    this.$store.dispatch("LoadData");
  },

  methods: {
    clientnew() {
      //this.$router.push({ name: "client", params: { id: -1 } });
      this.client_id = "-1";
      this.showScheduleForm = true;
    },
    startDownload() {
      this.snackbar_text = "Chargement données";
      this.snackbar_color = "primary";
      this.snackbar = true;
    },
    finishDownload() {
      this.snackbar_text = "Chargement terminé";
      this.snackbar_color = "success";
      //this.snackbar = false;
    },
    sortBy(prop) {
      return function(a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },
    editItem(item) {
      this.client_id = item.id;
      this.showScheduleForm = true;
      //this.$router.push({ name: "client", params: { id: item.id } });
    },
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir supprimer cet element?"
        )
      ) {
        this.deleteItemConfirm(item);
      }
    },
    deleteItemConfirm(item) {
      this.$apollo
        .mutate({
          mutation: DELETE_CLIENT,
          variables: {
            id: item.id,
          },
        })
        .then(() => {
          this.snackbar_color = "success";
          this.snackbar_text = "Item deleted";
          this.snackbar = true;
          this.$store.state.client_list.splice(this.devis.indexOf(item), 1);
        })
        .catch((error) => {
          this.snackbar_color = "error";
          this.snackbar_text = error.message;
          this.snackbar = true;
        });
    },
    rowClick(item, row) {
      row.select(true);
    },
  },
};
</script>

<style>
tr.v-data-table__selected {
  background: rgb(158, 184, 255) !important;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>
